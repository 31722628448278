<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()">
      <div v-if="getModalInfo" class="modal-container"
           @click.stop>
        <div class="close-div" v-if="!openCropper">
          <img @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
               src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>

        <div v-if="!openCropper" style="padding-bottom: 50px;">
          <div>
            <div v-if="disapprovalReasons.length" class="gigWarning">
              <template v-for="reason in disapprovalReasons">
                <h2 class="warningHeader">{{ reason.title }}</h2>
                <p class="warningText" v-html="reason.description"></p>
              </template>
            </div>


            <div style="display: block">
              <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Yayın Durumu</label>
              <select v-model="status" class="super-drop">
                <option :value="1">Yayında</option>
                <option :value="0">Pasif</option>
              </select>
            </div>

            <div class="hiddendiv20"></div>
            <div style="display: inline-block">
              <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Ana Kategori</label>
              <select v-model="selectedCategory" class="super-drop">
                <option disabled :value="null">Kategori Seç</option>
                <option v-for="category in categories" v-if="!category.parent_id" :value="category.id">{{ category.name }}</option>
              </select>
            </div>
            <div style="display: inline-block; margin-left: 30px;">
              <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Alt Kategori</label>
              <select :disabled="!selectedCategory" v-model="selectedSubCategory" class="super-drop">
                <option disabled :value="null">Kategori Seç</option>
                <option v-for="subCategory in categories"
                        v-if="selectedCategory && (selectedCategory === subCategory.parent_id)"
                        :value="subCategory.id">
                  {{ subCategory.name }}
                </option>
              </select>
            </div>

            <div class="hiddendiv20"></div>


            <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Başlık</label>
            <input maxlength="50" v-model="title" style="width: 300px" placeholder="Örneğin: Tasarladığım logolar"/>

            <div class="hiddendiv20"></div>

            <label style="font-weight: 400!important;  font-size: 17px; margin-bottom: 10px; text-align: left;">Açıklama</label>
            <textarea maxlength="500" v-model="description_textarea" style="width: 500px; height: 80px;" placeholder="İşinle ilgili kısa açıklama"></textarea>


            <div class="hiddendiv20"></div>


            <label class="portfolio-cover-header">Kapak Görseli</label>
            <div class="cover-container-passive" v-if="!uploadedCover.image_url && !dragAndDropArea">
              <label for="filesPortfolioCover" class="cover-label-container">
                <progress v-if="responseStatusToUploadCover === 'started'" :value='percentCompleted.portfolio[timeStamp]' max="100"></progress>
                <div style="padding-top: 36px;">
                  <img v-if="!responseStatusToUploadCover" :src="imageURL">
                  <div v-if="!responseStatusToUploadCover" class="cover-header">
                    Kapak Görseli
                  </div>
                </div>
                <input id="filesPortfolioCover" style="visibility:hidden;" type="file" @change="onFileChangeToUploadCover" v-if="responseStatusToUploadCover !== 'started'">
              </label>
            </div>
            <div id="portfolio-edit-drag-drop-cover"
                 v-if="!uploadedCover.image_url && dragAndDropArea"
                 draggable="true"
                 @drop="dropHandler($event, 'cover')"
                 @dragover="dragOverHandler($event)">
              {{dragDropTextCover}}
            </div>
            <div class="cover-container-active" v-if="uploadedCover.image_url">
              <img @click="removeCoverUpload()"
                   class="cover-remove-button"
                   :src="$root.CDN_SITE + 'icon/ic_delete.svg'">
              <img :style="{width: `${uploadedCover.width}px`, height: `${uploadedCover.height}px`}" :src="uploadedCover.image_url"
                   @load="coverImageOnload($event, uploadedCover)">
              <loader v-if="uploadedCover.loader"></loader>
            </div>

            <p style="font-size: 12px; margin-top: 10px; width: 400px;"> - Kapak görseli yüklenmediği takdirde otomatik olarak oluşturulacaktır.<br><br> - Portfolyo kapak görseli için önerilen boyut 16:9,<br> - Sadece png ve jpg formatında görseller yüklenebilir. <br> - Tavsiye edilen maksimum
              genişlik 1400px'dir.<br> - Maksimum dosya boyutu 2mb.</p>


            <div class="hiddendiv20"></div>

            <label class="portfolio-media-header">Ana İçerik</label>

            <div class="media-container-passive" v-if="!uploadedMedia.image_url && !dragAndDropArea">
              <label for="filesPortfolio3" class="media-label-container">
                <progress v-if="uploadResponseStatus === 'started'" :value='percentCompleted.portfolio[timeStamp]' max="100"></progress>
                <div style="padding-top: 90px;">
                  <img v-if="!uploadResponseStatus" :src="imageURL">
                  <div class="image-header" v-if="!uploadResponseStatus">
                    Yeni İçerik Ekle
                  </div>
                </div>
                <input id="filesPortfolio3" style="visibility:hidden;" type="file" @change="onFileChange" v-if="uploadResponseStatus !== 'started'">
              </label>
            </div>
            <div id="portfolio-edit-drag-drop-media"
                 v-if="!uploadedMedia.image_url && dragAndDropArea"
                 draggable="true"
                 @drop="dropHandler($event, 'media')"
                 @dragover="dragOverHandler($event)">
              {{dragDropTextMedia}}
            </div>

            <div class="video-container" v-if="uploadedMedia.file_type === 'video'">
              <img src="https://gcdn.bionluk.com/site/icon/icon_check_green.svg">
              <div class="video-separator"></div>
              <p class="warning-text"> Yükleme Başarılı. Ekleme işlemine devam edebilirsin. Yüklediğin video işlendikten sonra otomatik olarak iş ilanı sayfanda gözükecek. </p>
            </div>

            <div class="media-container-active" v-if="uploadedMedia.image_url">
              <img @click="removeMediaUpload()" class="media-remove-button" :src="$root.CDN_SITE + 'icon/ic_delete.svg'"/>
              <img class="media-image" :src="uploadedMedia.image_url" @load="imageOnload($event, uploadedMedia)" v-if="uploadedMedia.file_type === 'image' || uploadedMedia.file_type === 'video'" :style="{width: `${uploadedMedia.width}px`}" />
              <div class="audio-container" v-if="uploadedMedia.file_type === 'audio'">
                <img @click="removeMediaUpload()" class="media-remove-button" :src="$root.CDN_SITE + 'icon/ic_delete.svg'"/>
                <img class="media-play-icon" src="https://gcdn.bionluk.com/site/icon/ic_play_circle_fill.svg" @click="activateIframe()" v-if="!activatedIframe" />
                <img class="media-image" :src="uploadedMedia.image_url" @load="imageOnload($event, uploadedMedia)" v-if="!activatedIframe"  :style="{width: `${uploadedMedia.width}px`}"/>
                <audio
                        autoplay
                        controls
                        v-if="activatedIframe"
                        :src="initialOpenCDNURL+uploadedMedia.video_url"
                >
                </audio>

              </div>
              <loader v-if="uploadedMedia.loader"></loader>
            </div>
            <div class="hiddendiv10"></div>
            <p style="font-size: 12px; width: 370px"> - Sadece resim, video veya ses dosyası ekleyebilirsin.</p>
            <p style="font-size: 12px; width: 370px; margin-top: 5px;"> - Geçerli dosya formatları: <strong>.jpg, .png, .mp4 ve .mp3</strong></p>
            <p style="font-size: 12px; width: 370px; margin-top: 5px;"> - Resim dosyaları için maksimum boyut 2mb, video ve ses dosyaları için maksimum boyut 300 mb ve 90 saniyedir.</p>


            <!-- utku bitti -->

            <div class="hiddendiv"></div>

            <button @click="updatePortfolio()" :disabled="disableToSave || loadingBUTTONAMK" style="float: right; margin-right: 80px;" class="super-button">
              Güncelle
            </button>
            <a @click="deletePortfolio()" :disabled="disableDelete" class="delete_text" style="text-align: left">Sil</a>

          </div>
        </div>
        <cropper v-if="openCropper"
                 :file="file"
                 :fileType="fileType"
                 :canvasWidth="canvasWidth"
                 :canvasHeight="canvasHeight"
                 :divider="divider"
                 :viewMode="viewMode"></cropper>
      </div>
    </div>
  </transition>

</template>

<script>
  import draggable from 'vuedraggable';
  import cropper from '../../components/cropper/cropper.vue';


  export default {
    name: "src-pages-modals-editPortfolio-v1",
    components: {
      draggable,
      cropper
    },

    data() {
      return {
        loadingBUTTONAMK:false,
        //initialOpenCDNURL:'https://gcdn.bionluk.com/uploads/',
        initialOpenCDNURL:'',
        selectedCategory: null,
        selectedSubCategory: null,
        timeStamp: null,
        uploadResponseStatus: null,
        commentCount: null,
        description_textarea: null,
        favCount: null,
        fileURL: null,
        title: null,
        receivedUser: {},
        uuid: null,
        imageURL: `${this.$root.CDN_SITE}icon/icon_add_pink.svg`,
        viewCount: null,
        disableDelete: null,
        status: null,
        disapprovalReasons: [],
        uploadedMedia: [],
        totalFiles: 0,
        uploadedFileCount: 0,
        uploadedCover: {},
        responseStatusToUploadCover: null,
        openCropper: false,

//      for  cropper
        file: null,
        canvasWidth: 1400,
        canvasHeight: 788,
        divider: 2.5,
        viewMode: 1,
        fileType: "onFileChangeToUploadCover",
        sellerCategories: [],


        activatedIframe: false,
        dragAndDropArea: false,
        dragDropText: 'Dosyaları bu alana sürükle.',
        // dragdrop
        dragDropTextCover: '',
        dragDropTextMedia: ''
      }
    },
    methods: {
      hide() {
        this.cancelUploadRequest(this.timeStamp);
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      removeCoverUpload() {
        this.uploadedCover = {}
      },

      removeMediaUpload() {
        this.uploadedMedia = {};
        this.activatedIframe = false;
      },

      coverImageOnload(event, upload) {
        upload.width = 200;
        upload.height = 113;
        upload.loader = false;
      },

      imageOnload(event, upload) {
        upload.width = 400;
        upload.height = 226;
        upload.loader = false;
      },

      updatePortfolio() {
        this.activeProgress = true;
        this.loadingBUTTONAMK = true;
        let updatedPortfolio = {
          name: this.title,
          description: this.description_textarea,
          category_id: this.selectedCategory,
          category_sub_id: this.selectedSubCategory,
          uuid: this.uuid,
          status: this.status,
          upload_id: this.uploadedMedia.upload_id,
          cover_id: this.uploadedCover.upload_id,
        };

        this.api.seller.updatePortfolioV2(updatedPortfolio, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.EventBus.$emit('updatePortfolio', result.data.portfolio);
              this.loadingBUTTONAMK = false;
              this.hide();
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.loadingBUTTONAMK = false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.loadingBUTTONAMK = false;
          });
      },

      deletePortfolio() {
        this.disableDelete = true;
        if (confirm('Bu projeyi silmek üzeresin. Eğer bu portfolyo parçası bir iş ilanına bağlıysa oradan da silinecek. Bu yapmak istediğine emin misin?') === true) {
          this.loadingBUTTONAMK = true;
          this.api.seller.deletePortfolio(this.uuid, this.$Progress)
            .then(({data}) => {
              let result = data;
              this.disableDelete = null;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
                this.EventBus.$emit('deletePortfolio', this.uuid);
                this.loadingBUTTONAMK = false;
                this.hide();
              } else {
                this.$toasted.global.errorToast({description: result.message});
                this.loadingBUTTONAMK = false;
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              this.loadingBUTTONAMK = false;
            });
        }
      },

      onFileChangeToUploadCover(e) {
        let files = e.target.files || e.dataTransfer.files;

        this.api.general.checkCoverIsImage(files[0].type)
          .then(({data}) => {
            let result = data;
            this.activeProgress = null;
            if (result.success) {
              this.file = files[0];
              this.canvasWidth = 1400;
              this.canvasHeight = 788;
              this.divider = 2.5;
              this.viewMode = 1;
              this.fileType = "onFileChangeToUploadCover";
              this.openCropper = true;
              e.target.value = '';
            } else {
              e.target.value = '';
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            e.target.value = '';
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });

      },

      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        this.uploadResponseStatus = "started";
        this.timeStamp = +new Date();
        this.iterateUploadFileToGoogle(files[0], e);
      },

      eventBusOnFileChangeToUploadCover(payload) {
        this.responseStatusToUploadCover = "started";
        this.timeStamp = +new Date();

        this.uploadFileToGoogle(payload.file, this.Constants.UPLOAD_TYPES.PORTFOLIO, this.timeStamp)
          .then(result => {
            this.uploadedCover = {
              upload_id: result.externalID,
              image_url: result.url,
              width: 0,
              height: 0,
              loader: true
            };
            this.responseStatusToUploadCover = null;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
          });
      },

      iterateUploadFileToGoogle(files, e) {

        this.initialOpenCDNURL = '';
        this.uploadResponseStatus = 'started';

        this.uploadFileToGoogle(files, this.Constants.UPLOAD_TYPES.PORTFOLIO, this.timeStamp)
          .then(result => {
            this.uploadedMedia = {
              file_type: result.file_type,
              upload_id: result.externalID,
              image_url: result.url,
              video_url: result.video_url,
              width: 0,
              height: 0,
              loader: true
            };
            e.target.value = '';
            this.uploadResponseStatus = null;
            this.openCropper = false;
          })
          .catch(err => {
            e.target.value = '';
            this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.uploadResponseStatus = null;
            this.totalFiles -= 1;
          });

      },

      deactivateIframeLoader() {
        this.activatedIframe = false;
      },

      activateIframe() {
        this.activatedIframe = true;
      },

      dragStartEvent(event) {
        this.dragAndDropArea = true;
        if (event.target.id === "portfolio-edit-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana bırakın.';
        }

        else if (event.target.id === "portfolio-edit-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana bırakın.';
        }

      },

      dragLeaveEvent(event) {

        if (event.originalEvent && (!event.originalEvent.clientX && !event.originalEvent.clientY)) {
          //outside body / window
          this.dragAndDropArea = false;
        } else if ((!event.clientX && !event.clientY) || (!event.pageX && !event.pageY)) {
          this.dragAndDropArea = false;
        }

        else if (event.target.id === "portfolio-edit-drag-drop-cover") {
          this.dragDropTextCover = 'Dosyaları bu alana sürükle.';
        }

        else if (event.target.id === "portfolio-edit-drag-drop-media") {
          this.dragDropTextMedia = 'Dosyaları bu alana sürükle.';
        }
      },

      dropHandler(event, type) {


        this.EventBus.$emit('dragAndDropFile', null);

        event.preventDefault();
        event.stopPropagation();

        if (type === 'cover') {
          this.onFileChangeToUploadCover(event);
        }
        else if (type === 'media') {
          this.onFileChange(event);
        }
        this.dragAndDropArea = false;
      },

      dragOverHandler(event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
      },
    },

    computed: {
      getModalInfo() {
        if (!this.uuid) {
          let data = this.$store.state.activeModal.info.data;
          this.commentCount = data.comment_count;
          this.favCount = data.fav_count;
          this.viewCount = data.view_count;
          this.description = data.description;
          this.description_textarea = data.description_textarea;
          this.fileURL = data.image_url;
          this.title = data.name;
          this.receivedUser = data.user;
          this.uuid = data.uuid;
          this.selectedCategory = data.category_id;
          this.selectedSubCategory = data.category_sub_id;
          this.status = data.status;
          this.disapprovalReasons = data.disapproval_reasons;
          this.uploadedMedia = data.items[0];
          this.uploadedMedia["width"] = 400;
          this.uploadedMedia["height"] = 226;
          this.uploadedCover = data.cover;
          this.uploadedCover["width"] = 200;
          this.uploadedCover["height"] = 113;
        }
        return true;
      },

      disableToSave() {
        return !this.title || !this.selectedCategory || !this.selectedSubCategory || !this.fileURL || this.activeProgress || this.uploadResponseStatus || !this.uploadedMedia.upload_id;
      }
    },

    watch: {
      selectedCategory(newVal, oldVal) {
        if (oldVal !== undefined && oldVal !== null) {
          this.selectedSubCategory = null;
        }
      }
    },

    async created() {
      const categories = await this.$store.state.categoriesPromise
      window.addEventListener('dragleave', this.dragLeaveEvent, false);
      window.addEventListener('dragenter', this.dragStartEvent, false);
      this.user.seller.seller_categories.forEach((sellerCategory) => {
        categories.forEach(category => {
          if (sellerCategory.category_id === category.id) {
            let checkIfExist = this.sellerCategories.find(({ id }) => category.id)
            if (!checkIfExist) {
              this.sellerCategories.push(category);
            }
          }
          if (sellerCategory.category_sub_id === category.id) {
            this.sellerCategories.push(category);
          }
        });
      });

      this.EventBus.$on('cropperComponentImageFile', payload => {
        if (payload.fileType === "onFileChangeToUploadCover") {
          this.eventBusOnFileChangeToUploadCover(payload);
          this.openCropper = false;
        }

        if (payload.fileType === 0) {
          this.openCropper = false;
        }
      });
    },

    beforeDestroy() {
      window.removeEventListener('dragleave', this.dragLeaveEvent, false);
      window.removeEventListener('dragenter', this.dragStartEvent, false);
      this.EventBus.$off("cropperComponentImageFile");
    }
  }

</script>

<style scoped lang="scss">
  .modal-mask {
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    margin-top: 30px;
    width: 800px;
    height: 600px;
    overflow-y: scroll;
    position: relative;
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .gigWarning {
    text-align: center;
    height: auto;
    background-color: #FFBF00;
    margin-left: -30px;
    margin-bottom: 20px;
    margin-top: -20px;
    margin-right: -30px;
    padding: 20px;
  }

  .warningHeader {
    font-weight: 400;
    font-size: 24px;
    color: #2d3640;
    line-height: 44px;
  }

  .warningText {
    margin-top: 10px;
    font-size: 16px;
    color: #2d3640;
  }

  /*portfolio*/

  .portfolio-title-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .portfolio-cover-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .cover-label-container {
    cursor: pointer;
    height: 113px;
    margin: 0;
  }

  .cover-header {
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
    color: #bfc8d2
  }

  .cover-container-passive {
    width: 200px;
    height: 113px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .cover-container-active {
    width: 200px;
    height: 113px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
  }

  .cover-remove-button {
    cursor: pointer;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 20px;
  }

  .portfolio-add-right-side {
    flex: 4
  }

  .portfolio-media-header {
    font-weight: 400 !important;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: left;
  }

  .media-container-passive {
    width: 350px;
    height: 226px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .media-label-container {
    cursor: pointer;
    height: 226px;
    margin: 0;
  }

  .image-header {
    margin-top: 5px;
    font-size: 17px;
    font-weight: 400;
    color: #bfc8d2;
  }

  .media-container-active {
    position: relative;
    max-width: 400px;
    max-height: 350px;
    background-color: #fdfcfc;
    border: dashed 2px #ccd3d3;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .media-remove-button {
    cursor: pointer;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 20px;
    visibility: visible;
  }

  .media-image {
    max-width: 400px;
    max-height: 350px;
  }

  .audio-container {
    position: relative;
  }

  .media-play-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    border-radius: 50%;
    border: solid 1px #fff;
    background-color: #fff;
  }

  .video-container {
    width: 360px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(236, 249, 245);
    border: 1px solid rgb(103, 204, 100);
    padding: 8px 10px 8px 30px;
    margin-bottom: 15px;

    .video-separator {
      height: 40px;
      width: 0;
      margin-left: 19px;
      border-left: solid 1px #e6eaea;
    }

    .warning-text {
      font-size: 13px;
      margin-left: 18px;
      color: rgb(103, 204, 100);
    }
  }

  /*dragdrop*/
  #portfolio-edit-drag-drop-cover {
    position: relative;
    height: 115px;
    width: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 13px;
    color: rgb(230, 50, 98);
    font-weight: 500;
    border: 2px dashed rgb(230, 50, 98);
  }

  #portfolio-edit-drag-drop-media {
    position: relative;
    height: 230px;
    width: 355px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(white, 0.9);
    -webkit-transition: all 1s;
    transition: all 0.5s;
    font-size: 15px;
    color: rgb(230, 50, 98);
    font-weight: 500;
    border: 2px dashed rgb(230, 50, 98);
  }


</style>
